<template>
  <div class="content">
    <v-header></v-header>
    <div class="details">
      <!-- <h2>{{ data.name }} <span>以下数据内容由电话114提供</span></h2> -->
      <div class="con">
        <h3>{{data.name}}</h3>
        <div class="list">
          <h4>公司名称：</h4>
          <p>{{data.name}}</p>
        </div>

        <div class="list">
          <h4>公司分类：</h4>
          <p>{{data.type}}</p>
        </div>

        <div class="list">
          <h4>手机号：</h4>
          <p>
            <a v-for="(list,index) in data.phone" :key="index" :href="'tel:' + list">{{list}}</a>
          </p>
        </div>

        <div class="list">
          <h4>公司电话：</h4>
          <p>
            <a>{{data.gs_phone}}</a>
          </p>
        </div>

        <div class="list">
          <h4>微信：</h4>
          <p>{{data.vx_code}}</p>
        </div>

        <div class="list">
          <h4 class="sj">经营范围：</h4>
          <p>{{data.fanwei}}</p>
        </div>

        <div class="list">
          <h4>阿里：</h4>
          <p>{{data.al_code}}</p>
        </div>

        <div class="list">
          <h4>京东：</h4>
          <p></p>
        </div>

        <div class="list">
          <h4>淘宝：</h4>
          <p>{{data.tb_code}}</p>
        </div>

        <div class="list">
          <h4>快手：</h4>
          <p>{{data.ks_code}}</p>
        </div>

        <div class="list">
          <h4>抖音：</h4>
          <p>{{data.dy_code}}</p>
        </div>

        <div class="list">
          <h4>地址：</h4>
          <p>{{data.address}}</p>
        </div>

        <div class="list">
          <h4 class="sj">网站链接：</h4>
          <p style="width: 90%; line-height: 23px">
            <a :href="data.url" target="_blank">{{data.url}}</a>
          </p>
        </div>
      </div>
      <!-- <el-descriptions :column="2" size="medium" border>
        <el-descriptions-item
          label="公司名称"
          labelStyle="font-size:16px;color:rgba(0,0,0,.8);"
          :span="1"
        >
          <p style="font-size: 16px; color: rgba(0, 0, 0, 0.8)">
            {{ data.name }}
          </p>
        </el-descriptions-item>
        <el-descriptions-item
          label="公司分类"
          labelStyle="font-size:16px;color:rgba(0,0,0,.8);"
        >
          <p style="font-size: 16px; color: rgba(0, 0, 0, 0.8)">
            {{ data.type }}
          </p>
        </el-descriptions-item>
        <el-descriptions-item
          label="手机号"
          labelStyle="font-size:16px;color:rgba(0,0,0,.8);"
        >
          <p style="font-size: 16px; color: rgba(0, 0, 0, 0.8)">
            <a v-for="(list,index) in data.phone" :key="index" :href="'tel:' + list">{{list}}</a>
          </p>
        </el-descriptions-item>

        <el-descriptions-item
          label="公司电话"
          labelStyle="font-size:16px;color:rgba(0,0,0,.8);"
        >
          <p style="font-size: 16px; color: rgba(0, 0, 0, 0.8)">
            {{ data.gs_phone ? data.gs_phone : "暂无" }}
          </p>
        </el-descriptions-item>

        <el-descriptions-item
          label="微信"
          labelStyle="font-size:16px;color:rgba(0,0,0,.8);"
        >
          <p style="font-size: 16px; color: rgba(0, 0, 0, 0.8)">
            {{ data.vx_code ? data.vx_code : "暂无" }}
          </p>
        </el-descriptions-item>
        
        <el-descriptions-item
          label="网站链接"
          labelStyle="font-size:16px;color:rgba(0,0,0,.8);"
        >
          
          <a style="width:50px" v-if="data.url" :href="data.url">立即打开</a>
          <p v-else style="font-size: 16px; color: rgba(0, 0, 0, 0.8)">暂无</p>
        </el-descriptions-item>
      
           <el-descriptions-item
          label="阿里"
          labelStyle="font-size:16px;color:rgba(0,0,0,.8);"
        >
          <p style="font-size: 16px; color: rgba(0, 0, 0, 0.8)">
            {{ data.ks_code ? data.ks_code : "暂无" }}
          </p>
        </el-descriptions-item>
        <el-descriptions-item
          label="京东"
          labelStyle="font-size:16px;color:rgba(0,0,0,.8);"
        >
          <p style="font-size: 16px; color: rgba(0, 0, 0, 0.8)">
            {{ data.jd_code ? data.jd_code : "暂无" }}
          </p>
        </el-descriptions-item>
        <el-descriptions-item
          label="淘宝"
          labelStyle="font-size:16px;color:rgba(0,0,0,.8);"
        >
          <p style="font-size: 16px; color: rgba(0, 0, 0, 0.8)">
            {{ data.tb_code ? data.tb_code : "暂无" }}
          </p>
        </el-descriptions-item>
        <el-descriptions-item
          label="快手"
          labelStyle="font-size:16px;color:rgba(0,0,0,.8);"
        >
          <p style="font-size: 16px; color: rgba(0, 0, 0, 0.8)">
            {{ data.ks_code ? data.ks_code : "暂无" }}
          </p>
        </el-descriptions-item>
        <el-descriptions-item
          label="抖音"
          labelStyle="font-size:16px;color:rgba(0,0,0,.8);"
        >
          <p style="font-size: 16px; color: rgba(0, 0, 0, 0.8)">
            {{ data.dy_code ? data.dy_code : "暂无" }}
          </p>
        </el-descriptions-item>
        <el-descriptions-item
          label="地址"
          labelStyle="font-size:16px;color:rgba(0,0,0,.8);"
        >
          <p style="font-size: 16px; color: rgba(0, 0, 0, 0.8)">
            {{ data.address ? data.address : "暂无" }}
          </p>
        </el-descriptions-item>

        <el-descriptions-item
          label="经营范围"
          labelStyle="font-size:16px;color:rgba(0,0,0,.8);"
        >
         <p style="font-size: 16px; color: rgba(0, 0, 0, 0.8)">
            {{ data.fanwei ? data.fanwei : "暂无" }}
          </p>
        </el-descriptions-item>

        <el-descriptions-item
          label="物流路线"
          labelStyle="font-size:16px;color:rgba(0,0,0,.8);"
        >
         <p v-if="data.start && data.arrive" style="font-size: 16px; color: rgba(0, 0, 0, 0.8)">
            {{ data.start + '到' + data.arrive }}
          </p>
        </el-descriptions-item>
      </el-descriptions> -->
    </div>
    <div class="footer" style="font-size: 14px;width: 95%;margin: auto;border-top: 1px solid #f5f3f7;padding-top: 10px;text-align: center;font-weight: 400;">
      Copyright ©2023-2024 德州众搜信息技术有限公司 <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">鲁ICP备2023050409号-1</a>
    </div>
  </div>
</template>
        
  <script>
import { getDetails } from "@/api/product";
import header from "@/components/header";
export default {
  components: {
    "v-header": header,
  },
  data() {
    return {
      data: [],
      pageNum: 1,
      pageSize: 100,
      total: 0,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      getDetails(window.location.href.split("?id=")[1]).then((res) => {
        this.data = res.data;
      });
    },
  },
};
</script>
        
  <style lang="less" scoped>
.details {
  width: 95%;
  margin: auto;
  margin-top: 20px;
  a {
    margin-right: 10px;
    font-size: 16px;
    color: rgb(237, 125, 48);
    font-weight: 800;
  }
  h2 {
    font-size: 25px;
    margin-bottom: 20px;
    span {
      font-size: 15px;
      color: rgba(0, 0, 0, 0.6);
    }
  }
}

.con {
  width: 1100px;
  height: auto;
  margin: 20px auto;
  background: #fff;
  padding-bottom: 20px;
}

.con h3 {
  width: 95%;
  height: 80px;
  line-height: 80px;
  font-size: 30px;
  margin: auto;
  text-align: center;
  margin-bottom: 30px;
}

.con .list {
  width: 90%;
  height: auto;
  /*line-height: 40px;*/
  margin: auto;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.con .list h4 {
  width: 120px;
  font-size: 19px;
}

.con .list p {
  width: 80%;
  font-size: 19px;
}

@media (max-width: 680px) {
  .details {
    width: 100%;
  }
  .con {
    width: 100%;
    h3 {
      font-size: 0.4rem;
      height: auto !important;
      line-height: normal;
    }
    .list {
      h4 {
        font-size: 0.35rem;
      }
      p {
        font-size: 0.35rem;
      }
    }
  }
}
</style>